input,
  select {
    padding: 7px;
    height: 30px;
    outline: none;
    width: 200px;
    border-radius: 5px;
    border: 1px solid rgb(182, 182, 182);
    margin-bottom: 0.3rem;
  }

