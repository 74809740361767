.modalBackground {
    width: 0vw;
    height: 0vh;
    background-color: rgb(152, 61, 61);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer {
    width: 400px;
    height: 250px;
    border-radius: 12px;
    background-color: rgb(135, 11, 11);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 30px;
  }
  
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    text-align: right;
  }
  
  .modalContainer .body {
    flex: 250%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
    text-align: center;
  }

  
  .modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer .footer button {
    width: 250px;
    height: 35px;
    margin: 10px;
    border: none;
    background-color: rgb(88, 67, 134);
    color: white;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
  }
  
  #cancelBtn {
    background-color: rgb(241, 243, 240);
  }
  #actionBtn {
    background-color: rgb(241, 243, 240);
  }

  .actionButtons {
    align-items: center;
    width: 100px;
    height: 35px;
    color: white;
    background-color:rgb(21, 12, 121);
  }
  .tdclass {
    width: 30px;
  }