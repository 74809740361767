    .modalBackground {
      width: 700px;
      height: 300px;
      background-color: rgb(254, 255, 254);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .modalContainer {
      width: 650px;
      height: 275px;
      border-radius: 12px;
      background-color: white;
      box-shadow: rgb(253, 249, 249) 0px 5px 15px;
      display: flex;
      flex-direction: column;
      padding: 25px;
    }
    
    .modalContainer .title {
      display: inline-block;
      text-align: center;
      margin-top: 10px;
    }
    
    .titleCloseBtn {
      display: flex;
      justify-content: flex-end;
    }
    
    .titleCloseBtn button {
      background-color: transparent;
      border: none;
      font-size: 20px;
      cursor: pointer;
      text-align: right;
    }
    
    .modalContainer .body {
      width: 650px;
      height: 275px;
      justify-content: center;
      align-items: center;
      font-size: 1.4rem;
      text-align: center;
    }
  
    
    .modalContainer .footer {
      flex: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .modalContainer .footer button {
      width: 150px;
      height: 35px;
      margin: 10px;
      border: none;
      background-color: rgb(249, 251, 254);
      color: white;
      border-radius: 8px;
      font-size: 18px;
      cursor: pointer;
    }
  
    #cancelBtn {
      background-color: rgb(42, 85, 226);
      position: absolute
    }
    .tdclass{
      text-align: left;
    }