*{ 
    margin: 0; 
    padding: 0; 
    box-sizing: border-box
}

.settings {
    padding-left: 30px;
    padding-top: 30px;
}
Button { 
    width:200px;
    height: 40px;
}
.checkbox {
    margin-left: -85px;
    margin-top: 0px;
    padding-top: 0px;
    height: 25px;
  }

  .custom-select {
    padding: 7px;
    height: 40px;
    width: 180px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(182, 182, 182);
    margin-bottom: 0.3rem;
  }
  .select {
    height: 40px;
    width:255px;
  }
  .customselect {
    padding: 0px;
    margin-left: 45px;
    font-weight: bold;
    height: 40px;
    width: 180px;
    outline: none;
    margin-bottom: 0.3rem;
  }
.BlueLabel{
  font-weight:100;
  width: 300px;
}

.LongLabel {
  font-weight:100;
  width: 1300px;
}
  .theButtons {
    background-color: rgb(43, 27, 137);
    color: white;
    height: 35px;
    width: 145px;
    border: none;
    border-radius: 6px;  
    margin-top: 2px;
    display: grid;
    margin-left: 50px
  }

  .viewButton {
    background-color: rgb(24, 108, 218);
    color: black;
    height: 30px;
    width: 100px;
    margin-top: 2px;
    display: grid;
    margin-left: 50px
  }
  .buttons{
    text-align: center;
  }

  .viewnewwButton {
    background-color: rgb(24, 108, 218);
    color: black;
    height: 30px;
    width: 70px;
    margin-top: 2px;
    display: block;
    margin-right: 0px;    
  }


  .flex { 
    display :flex; 
  }
  .editbutton{
    text-align:center;
  }
  .editButton {
    background-color: rgb(9, 198, 166);
    color: black;
    height: 30px;
    width: 100px;
    margin-top: 2px;
    display: grid;
    margin-left: 10px
  }

  .theAll {
    background-color: rgb(40, 68, 250);
    color: white;
    height: 40px;
    width: 110px;
    border: none;
    margin-left: 2px;
    border-radius: 6px;  
    display: grid;
  }
  .theNew {
    background-color: rgb(40, 68, 250);
    color: white;
    height: 40px;
    width: 80px;
    border: none;
    margin-left: 2px;
    border-radius: 6px;  
    display: grid;
  }
  .theTh {
    width: 130px
  }

  .mediumInput {
    width: 400px
  }

  .shortInput {
    width: 350px
  }

  .accessInput {
    width: 960px
  }