.Profile {
    width: 70vh;
    margin: 5rem auto;
  }

  .Details {
    color: darkblue;
    margin-left: 30px;
    font-size: 18px;
    font-style: italic;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 40%;
    margin-left: 20px;
  }
  
  input,
  select {
    padding: 7px;
    height: 30px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(182, 182, 182);
    margin-bottom: 0.3rem;
  }

  .longinput
   {
    height: 120px;
    width:1050px;
    outline: none;
    border-radius: 0px;
    border: 0px solid rgb(182, 182, 182);
    margin-bottom: 0.3rem;
  }

  .radiusinput
  {
   height: 30px;
   width:1050px;
   outline: none;
   border-radius: 1px;
   border: 1px solid rgb(182, 182, 182);
   margin-bottom: 0.3rem;
 }

  .descinput
  {
   height: 70px;
   width:300px;
   outline: none;
   border-radius: 2px;
   border: 2px solid rgb(182, 182, 182);
   margin-bottom: 0.3rem;
 }
  .displayinput
  {
   width:1050px;
   outline: 1px;
   border-radius: 2px;
   border: 2px solid rgb(182, 182, 182);
   margin-bottom: 0.3rem;
 }
  
  .shortinput{
    padding: 7px;
    height: 30px;
    width:450px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(182, 182, 182);
    margin-bottom: 0.3rem;
  }

  .mediuminput{
    padding: 7px;
    height: 30px;
    width:50px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(182, 182, 182);
    margin-bottom: 0.3rem;
  }


  .tinyinput {
    width:150px;
  }

  .mediuminput{
    padding: 7px;
    height: 30px;
    width:850px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(182, 182, 182);
    margin-bottom: 0.3rem;
  }

  .checkbox {
    padding: 0px;
    margin-left: 2px;
    margin-right: 7px;
    margin-bottom: 10px;
    height: 15px;
  }

  form p {
    margin: 0;
    padding: 0;
    margin-bottom: 0.5rem;
    color: red;
    font-size: 12px;
  }
  
  input:focus {
    border: 1px solid rgb(0, 208, 255);
  }

  .primary {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color:brown;
    font-size: 28px;
    color: white;
  }
  .contact {
    background-color:brown;
    font-size: 18px;
    color: white;
  }

  .Flex {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .KPI {
    background: rgb(179, 186, 186);
    box-shadow: 4px 3px 8px 1px #30273b;
  	-webkit-box-shadow: 4px 3px 18px 10px #969696;
    width: 100%;
    height: 135px;
    display: flex;
    margin: 12px;
  }
  .KPI, span {
    margin: 5px;
  }

  .button, Button {
    background-color: rgb(216, 117, 124);
    color: black;
    height: 30px;
    width: 100px;
    margin-top: 2px;
    display: grid;
    margin-left: 50px
  }

  .submit {
    background-color: rgb(103, 113, 199);
    color: black;
    height: 30px;
    width: 80px;
    margin-top: 2px;
    display: grid;
    margin-left: -12px
  }

  .operation {
    margin-left: 40px
  }

  .mainhead {
    margin-left: -12px
  }

  .heading {
    text-align: left;
    color: darkblue;
    padding-left: 20px;
  }
  .home {
    background-color: grey;
    height: 100%;
    width:100%;
    display: grid;
  }
  table th {
    text-align: left;
  }
  .selectButton {
    width: 75px;  
    height: 35px;
    background-color: lightgrey
  }

  .ruleButton {
    width: 85px;
    height: 35px;
    background-color: rgb(141, 179, 95)
  }

  .mediaButton {
    width: 185px;
    height: 45px;
    text-align: right;
    background-color: white;
    color: lightgreen
  }
  .mediaURL {
    width: 1000px;
    height: 45px;
    text-align: left;
    background-color: rgb(141, 179, 95)
  }
  .mediaType {
    width: 150px;
    height: 45px;
    text-align: left;
    background-color: rgb(141, 179, 95)
  }

  .Grid  {
    margin-left: -63px;
  }
  .modal {
    margin-left:200px;
  }
  .cardAdd {
    margin: 2rem auto;
    background:red;
    padding: 1rem;
    width: 90%;
    max-width: 40rem;	
  }
  .hidden{
    visibility: hidden;
    margin-top:0;
  }
  .block {
    display: block;
    margin-top:0;
  }
  .select {
    height: 38px;
    width: 200px;
    color: darkblue;
  }

  .tdclass{
    width: auto;
    height: auto;
  }
  .alertDate{
    width: 250px;
    height: 20px;
  }
  .alertType{
    width: 150px;
    height: 20px;
  }
  .alertTime{
    width: 250px;
    height: 20px;
  }
  .alertMessage {
    width: 600px;
    max-width: 600px;
    word-wrap: break-word; 
    height: 20px;
  }

  .FieldsTable {
    margin-left: 10px;
  }